.termsAndPrivacy {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    flex-direction: column;
    background-color: #222222;
}

.termsAndPrivacyContent {
    height: auto;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    /*margin-right: 5vw;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.textComponentTermsAndPrivacy {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 5%;
    padding-left: 5%;
    align-items: center;
    flex-basis: 80%;
    background: rgba(0,0,0, 0.85);
    border-radius: 80px;
    color: white;
    line-height: 1.4;
    white-space: break-spaces;
}

