.headBar {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    position: absolute;
    width: 100%;
    background: #222222;
}

.headStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow:hidden;
    background-color: #222222;
    justify-content: center;
    width: 100%;
    height: 13vh;
    position: fixed;
    z-index: 3;

}

.blog {
    padding: 1%;
    /*width: 80px;*/
    white-space: nowrap;
    text-align: center;
    font-family: Georgia;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,193,7,1);
    margin-left: 10px;
    margin-right: 10px;
}

.shop {
    padding: 1%;
    /*width: 80px;*/
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,87,87,1);
    margin-left: 10px;
    margin-right: 10px;
}

.contact {
    padding: 1%;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,102,196,1);
    margin-left: 10px;
    margin-right: 10px;
}

.icon {
    width: 37px;
    height: 37px;
    margin-left: 10px;
    margin-right: 10px;
}

.navigation {
    display: flex;
    padding: 1%;
    padding-right: 0;
    flex-basis: 33%;
    width: fit-content;
    /*min-height: 13vh;*/
    justify-content: flex-start;
    align-items: flex-end;
}

.navigation a:hover {
    /*color: #cdcdcd;*/
    /*padding: 5px;*/
    /*margin-bottom: 200px;*/
    background-color: #000000;
    border: 2px solid #000000;
    border-radius: 30px;
}

.socials a:hover {
    /*color: #cdcdcd;*/
    /*padding: 5px;*/
    /*margin-bottom: 200px;*/
    background-color: #222222;
    border: 2px solid #222222;
}

.socials {
    /*margin-bottom: 2%;*/
    padding: 1%;
    padding-left: 0;
    display: flex;
    flex-basis: 33%;
    /*min-height: 13vh;*/
    /*width: 34vw;*/
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.imageInHead {
    width: auto;
    /*width: 45%;*/
    max-height: 11vh;
    /*min-width: 230px;*/
    align-self: center;
}

@media screen and (max-width: 500px){
    .imageInHead {
        /*width: 70%;*/
        /*min-width: 150px;*/
        margin: auto;
        /*margin-right: 10px;*/
    }
}



