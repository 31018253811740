@import '~react-dropdown/style.css';

.shopStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: auto;
    overflow: hidden;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
}

@media screen and (max-width: 1500px) {
    .shopStyle {
        width: 100%;
        height: 100%;
        overflow-scrolling: auto;
        overflow: auto;
        /*position: absolute;*/
        display: flex;
        z-index: 2;
    }

}

/*@media screen and (max-width: 1500px) {*/
/*    .shopStyle {*/
/*        width: 100%;*/
/*        !*height: 100vh;*!*/
/*        overflow-scrolling: touch;*/
/*        display: flex;*/
/*        overflow-y: auto;*/
/*        !*overflow:scroll;*!*/
/*    }*/
/*}*/

.shopBackground {
    background-image: url("/src/images/backgroundShopList.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*min-height: 100%;*/
}
.shopWrapper {
    width: 100%;
    height: auto;
    margin-left: 10px;
    /*margin-right: 5vw;*/
    display: flex;
    flex-direction: row;
    /*flex-wrap: wrap;*/
    /*position: fixed;*/
}

@media screen and (max-width: 1500px) {
    .shopWrapper {
        /*min-height: 100%;*/
        margin-left: 2%;
        /*margin-right: 2%;*/
        display: block;
        flex-direction: column;
        /*height: max-content;*/
        /*overflow-y: scroll;*/
        height: auto;
        position: relative;
    }
}


.shopItem {
    align-items: center;
    border-radius: 170px;
    background: rgba(0,0,0, 1);
    /*opacity: 0.85;*/
    width: 100%;
    display: flex;
    flex-basis: 40%;
    /*max-width: 80%;*/
    flex-direction: row;
    position: relative;

}

@media screen and (max-width: 550px){
    .shopItem {
        align-items: center;
        border-radius: 170px;
        /*background: rgb(0,0,0);*/
        /*opacity: 0.85;*/
        width: 100%;
        display: flex;
        flex-basis: 40%;
        /*max-width: 80%;*/
        flex-direction: row;
        position: relative;

    }
}

.nameElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 45%;
    min-height: 200px;
    padding-left: 2%;
}


.nameSize {
    font-size: 2vw;
    line-height: 1.1;
    text-align: center;
    font-family: Georgia;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: break-spaces;
}
@media screen and (max-width: 900px){
    .nameSize {
        font-size: 4vw;
        line-height: 1;
    }
}

.subjectSize {
    font-size: 1.5vw;
    font-family: Georgia;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 900px){
    .subjectSize {
        font-size: 3vw;
    }
}

.shopItemImage {
    display: flex;
    max-width: 100%;
    /*max-height: 270px;*/
    z-index: 1;
}

@media screen and (max-width: 550px){
    .shopItemImage {
        width: 100%;
        height: 200px;
    }
}

.shopItemImageContainer {
    display: flex;
    max-width: 40%;
    /*max-height: 270px;*/
    z-index: 1;
}

@media screen and (max-width: 550px){
    .shopItemImageContainer {
        max-width: 60%;
        height: 200px;
    }
}



.price {
    height: 20%;
    width: 35%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    left: 15%;
    border-radius: 50px;
    padding-left: 5%;
    color: white;
    z-index: 98;
    font-size: 200%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Georgia;
}

@media screen and (max-width: 800px){
    .price {
        /*bottom: 1;*/
    }
}

.addToCartButton {
    height: 20%;
    width: 30%;
    background: rgba(1,123,23,0.5);
    position: absolute;
    bottom: 0;
    right: 25%;
    border-radius: 50px;
    /*padding-left: 5%;*/
    color: white;
    /*background-color: red;*/
    z-index: 100;
    font-size: 200%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Georgia;
}

.addToCartButtonText {
    margin: auto;
    /*margin-left: 0;*/
}

.priceText {
    margin: auto;
    margin-left: 0;
}

.manageColumn {
    /*margin-bottom: 1%;*/
    display: flex;
    height: 86vh;
    flex-basis: 20%;
    margin:10px;
    flex-direction: column;
    justify-content: center;
    /*opacity: 0.85;*/
    /*overscroll-behavior: contain;*/
}

@media screen and (max-width: 1500px) {
    .manageColumn {
        display: flex;
        flex-basis: 10%;
        margin:10px;
        height: auto;
        flex-direction: row;
        justify-content: center;
        /*opacity: 0.85;*/
    }
}
@media screen and (max-width: 500px) {
    .manageColumn {
        display: flex;
        flex-basis: 10%;
        margin:10px;
        height: auto;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        /*opacity: 0.85;*/
    }
}

.filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*flex-basis: 45%;*/
    align-items: stretch;
}



.notScrollable {
    overflow: hidden;
}

.productsColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /*flex-basis: 80%;*/
    height: 86vh;
    padding-bottom: 2%;
    padding-left: 10px;
    overflow-y: auto;
    flex:1;
}

@media screen and (max-width: 1500px){
    .productsColumn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        /*flex-basis: 100%;*/
        /*min-height: max-content;*/
        /*max-height: max-content;*/
        padding-bottom: 2%;
        margin:10px;
        overflow: hidden;
        overflow-y: hidden;
        height: auto;
        flex:1;
    }
}

@media screen and (max-width: 900px){
    .productsColumn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        /*flex-basis: 100%;*/
        /*min-height: max-content;*/
        /*max-height: max-content;*/
        padding-bottom: 2%;
        padding-left: 10px;
        overflow: hidden;
        /*overflow-y: hidden;*/
        flex:1;
    }
}


.shopItemLinkWrapper {
    flex-basis: 45%;
    /*flex-grow: 1;*/
    /*flex-shrink: 1;*/
    padding: 20px;
}

@media screen and (max-width: 900px){
    .shopItemLinkWrapper {
        flex-basis: 100%;
        /*flex-grow: 1;*/
        /*flex-shrink: 1;*/
        padding: 5px;
    }
}

.filterElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*flex-basis: 50%;*/
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
}

.labelStyle {
    color: rgb(255, 222, 89);
    margin-bottom: 0px;
    /*margin-left: 2%;*/
    width: 100%;
}

.filterList {
    border-radius: 5px;
    background-color: rgb(255, 222, 89);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: stretch;
    text-align: center;
}

.filterPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filterPriceRow {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.filterPriceRow input {
    color:white;
    background-color: #222222;
    border-color: rgb(255, 222, 89);
    width: 50px;
}
.priceLine {
    color: rgb(255, 222, 89);
    margin-bottom: 8px;
    margin-top: 8px;
    height: auto;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.wrongPrice {
    color: red;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.filterList p {
    margin-bottom: 0;
    user-select: none;
}

.img {
    width: auto;
    height: auto;
}

.trolleyPictureShop {
    padding-top: 10px;
    width: 15%;
    height: auto;
}

.totalCost {
    display: flex;
    color:white;
    flex-basis: 45%;
    background: rgba(0,0,0, 0.85);
    border-radius: 160px;
    /*padding: 20px;*/
    flex-direction: column;
    align-self: auto;
    align-items: center;
    margin: 10px;
}

@media screen and (max-width: 1500px){
    .totalCost {
        display: flex;
        color:white;
        flex-basis: 45%;
        border-radius: 100px;
        /*padding: 20px;*/
        flex-direction: column;
        align-self: auto;
        align-items: center;
        margin: 10px;
    }
}

@media screen and (max-width: 500px){
    .totalCost {
        color: white;
        /*flex-basis: 45%;*/
        background: rgba(0,0,0,1);
        border-radius: 100px;
        display: flex;
        width: 82%;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        margin: 10px;
    }
}

.shopRow {
    display: flex;
    margin:10px;
}

@media screen and (max-width: 1150px) {
    .shopRow {
        display: flex;
        flex-direction: column;
        margin:10px;
    }
}

.shopList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.filtersWrapper {
    background: rgba(0,0,0,0.85);
    border-radius: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 45%;
    /*padding: 20px;*/
    margin: 10px;
}

@media screen and (max-width: 1500px) {
    .filtersWrapper {
        background: rgba(0,0,0,1);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*flex-basis: 45%;*/
        width: 70%;
        padding: 20px;
        margin: 10px;
    }
}

@media screen and (max-width: 500px) {
    .filtersWrapper {
        background: rgba(0,0,0,1);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*flex-basis: 45%;*/
        width: 82%;

        padding: 20px;
        margin: 10px;
    }
}

.itemListScroll {
    overflow: auto;
    height: 12vh;
}

.chosenItemsListElementShop {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 0;
    text-align: center;
}
.chosenItemsListElementShop:hover {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 0;
    user-select: none;
}

.shopBagRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    /*margin-bottom: 200px;*/
    border: 2px solid #222222;
}
.shopBagRow:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    /*margin-bottom: 200px;*/
    border: 2px solid #225673;
}


.payNowButton {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: rgb(255, 222, 89);
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 3%;
    /*min-font-size: 2vw;*/
    /*font-size: 2vw;*/
    font-size: 20px;
}

.dropDown {
    width: 100%;
}

.loadingGif {
    max-width:100%;
    max-height:100%;
}

.filtersWrapperMobile {
    background: rgba(0,0,0,1);
    border-radius: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 45%;
    /*padding: 20px;*/
    margin: 10px;
}

.filtersMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /*flex-basis: 45%;*/
    align-items: stretch;
}

@media screen and (max-width: 650px){
    .filtersMobile {
        flex-direction: column;
    }
}

.filterElementMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*flex-basis: 50%;*/
    align-items: center;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    margin-left: 15px;
}

@media screen and (max-width: 650px){
    .filterElementMobile {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
