.shopThanksStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    background-color: #222222;
}

.thanksBackground {
    background-image: url("/src/images/thanksBackground.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*height: auto;*/
    /*width: 100%;*/
    position: relative;
}

.shopThanksWrapper {
    height: 87%;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 3%;
    padding-top: 3%;
    display: flex;
    flex-direction: column;
}

.shopThanksImage {
    /*align-self: center;*/
    width: 50%;
    height: auto;
    margin: auto;
    padding-bottom: 13vh;
}

@media screen and (max-width: 1150px){
    .shopThanksImage {
        width: 75%;
    }
}

/*@media screen and (max-width: 800px){*/
/*    .shopThanksImage {*/
/*        height: 30%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 600px){*/
/*    .shopThanksImage {*/
/*        height: 20%;*/
/*    }*/
/*}*/
