.blogStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    flex-direction: column;
}

.blogBackground {
    background-image: url("/src/images/backgroundBlog.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*height: auto;*/
    /*width: 100%;*/
    position: relative;
}

.blogWrapper {
    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
    display: block;
}

.blogContainerStyle {
    /*width: 100%;*/
    height: auto;
    margin-left: 10px;
    /*margin-right: 5vw;*/
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    /*position: absolute;*/
}

.blogItem {
    display: flex;
    justify-content: center;
    padding: 2%;
    flex-basis: 23%;
    border-radius: 15px;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .blogItem {
        /*padding: 5%;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 38%;
        border-radius: 15px;
    }
}

@media screen and (max-width: 830px) {
    .blogItem {
        /*padding: 5%;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 75%;
        border-radius: 15px;
    }
}

.blogImage {
    display: flex;
    max-width: 90%;
    border-radius: 15px;
}

.blogImage2 {
    display: flex;
    max-width: 90%;
    border-radius: 15px;
    opacity: 0.5;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    /*background: #282c34;*/
    border: 0px inset #ffffff;
    border-radius: 45px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255, 222, 89, 1);
    border: 0px none #ffffff;
    border-radius: 50px;
    /*height: 70%;*/

}

/* Handle on hover */
/*::-webkit-scrollbar-thumb:hover {*/
/*    background: rgba(9, 9, 9, 1);*/
/*    background-clip: content-box;*/
/*    border: 5px solid transparent;*/
/*}*/

::-webkit-scrollbar-button {
    width: 100px;
    height: 100px;
    left: -100px;
}

.BackButton {
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    width: 10%;
    max-width: 50px;
    /*min-height: 5%;*/
    border-radius: 15px;
    position: fixed;
    top: 13vh;
    left: 10px;
    /*user-select: none;*/
    height: auto;
    z-index: 99;
    opacity: 0.8;
}

.BackButton:hover {
    border: 3px solid rgba(0,0,0,0);
}

.welcomeTextImage {
    align-self: center;
    width: auto;
    height: 30%;
    /*min-width: 400px;*/
}

@media screen and (max-width: 1000px){
    .welcomeTextImage {
        align-self: center;
        width: 95%;
        /*height: 30%;*/
        /*min-width: 400px;*/
    }
}

.textHover {
    color: white;
    font-size: 35px;
    /*min-font-size: 30px;*/
    position: absolute;
    /*padding: 2%;*/
    display: flex;
    width: min-content;
    /*min-width: 200px;*/
    align-self: center;
    text-align: center;
    border-radius: 15px;
    flex-wrap: wrap;
    text-wrap: normal;
}

/*@media screen and (max-width: 1200px) {*/
/*    .textHover {*/
/*        max-width: 38vw;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 830px) {*/
/*    .textHover {*/
/*        max-width: 75vw;*/
/*    }*/
/*}*/



@media screen and (max-width: 500px) {
    .textHover {
        font-size: 20px;
    }
}

.blogDetails {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    flex-direction: column;
}

.blogContent {
    height: auto;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    /*margin-right: 5vw;*/
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.blogDetailsBackground {
    /*background-image: url("/src/images/backgroundBlog.png");*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*height: auto;*/
    /*width: 100%;*/
    position: relative;
}
.titleComponent {
    max-height: 200px;
    width: auto;
    margin-right: 5%;
    margin-left: 5%;
    justify-content: center;
    display: flex;
}

.titleComponent img {
    display: flex;
    height: 200px;
    margin: 0;
    align-self: center;
}

@media screen and (max-width: 900px){
    .titleComponent img {
        height: 100px;
        max-width: 100%;
    }
}

.textComponent {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 5%;
    padding-left: 5%;
    align-items: center;
    flex-basis: 80%;
    background: rgba(0,0,0, 0.85);
    border-radius: 80px;
    color: white;
    line-height: 1.4;
    white-space: break-spaces;
}

.textComponent {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 5%;
    padding-left: 5%;
    align-items: center;
    flex-basis: 80%;
    background: rgba(0,0,0, 0.85);
    border-radius: 80px;
    color: white;
    line-height: 1.4;
    white-space: break-spaces;
}

@media screen and (max-width: 900px) {
    .textComponent {
        border-radius: 50px;
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

.loading {
    width:100vw;
    height:auto;
    background-color: #222222;
}





