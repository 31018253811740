.shopBagStyle {
    /*width: 100%;*/
    /*height: 100%;*/
    /*top: 0;*/
    /*left: 0;*/
    /*overflow-scrolling: touch;*/
    /*overflow: auto;*/
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: hidden;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    background-color: #222222;
}

/*.shopBagBackground {*/
/*    background-image: url("/src/pictures/backgroundShopList.png");*/
/*    background-color: #222222;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/
/*    background-attachment: fixed;*/
/*    min-height: 100%;*/
/*}*/


.shopBagWrapper {
    height: 87%;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 3%;
    padding-top: 3%;
    display: flex;
    /*flex-direction: row;*/
    flex-direction: column;
}

.bag {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 3%;
    padding-bottom: 3%;
    align-items: center;
    flex-basis: 80%;
    background: rgb(0,0,0);
    opacity: 0.85;
    border-radius: 80px;
    /*height: 100%;*/
}

@media screen and (min-width: 1150px) {
    .bag {
        height: 85%;
    }
}

.titleBar {
    display: flex;
    /*flex-basis: 100%;*/
    flex-direction: row;
    justify-content: flex-start;
    color: white;
    font-size: 40px;
    font-weight: bold;
    /*flex:10;*/
    /*text-align: center;*/
    /*align-items: baseline;*/
    align-content: center;
}

@media screen and (max-width: 500px){
    .titleBar {
        display: flex;
        /*flex-basis: 100%;*/
        flex-direction: row;
        justify-content: flex-start;
        color: white;
        font-size: 30px;
        font-weight: bold;
        /*flex:10;*/
        /*text-align: center;*/
        /*align-items: baseline;*/
        align-content: center;
    }
}

.titleBar p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleAndListWrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: flex-start;
    height: 90%;
    overflow: hidden;
}

.chosenItemsListElementInBag {
    font-size: 40px;
    font-weight: bold;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 850px) {
    .chosenItemsListElementInBag {
        font-size: 20px;
        font-weight: bold;
        color: white;
    }
}
.totalCostBag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
    color: white;
    border-top: 2px solid white;
    font-size: 30px;
}

@media screen and (max-width: 1100px) {
    .totalCostBag {
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 850px) {
    .totalCostBag {
        border-top: 2px solid white;
        font-size: 25px;
    }
}

.totalCostBag input {
    height: 50px;
    color:white;
    background-color: #222222;
    border-color: rgb(255, 222, 89);
}
.totalCostBag p {
    margin: 0;
}


.payOrShopButtons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;

}
@media screen and (max-width: 500px) {
    .payOrShopButtons {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        margin-bottom: 15px;

    }
}

.payButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: #FF66C4;
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    /*min-font-size: 2vw;*/
    font-size: 2vw;
}

@media screen and (max-width: 850px) {
    .payButton {
        font-size: 20px;
    }
}

.shopMore {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: rgb(255, 222, 89);
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    /*min-font-size: 2vw;*/
    font-size: 2vw;
}

@media screen and (max-width: 850px) {
    .shopMore {
        font-size: 20px;
    }
}

.itemListScrollBag {
    overflow: auto;
    /*overflow-x: auto;*/
    height: 100%;
}

.chosenItemsListWrapper {
    display: flex;
    height: 100%;
    overflow: auto;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
}

.bagLine {
    height: calc(0.05*60vw);
    width: 60%;
    align-self: center;
}

@media  screen and (max-width: 800px) {
    .bagLine {
        height: calc(0.05*100vw);
        width: 100%;
    }
}

.shopBagRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    /*margin-bottom: 200px;*/
    border: 2px solid #222222;
    color: white;
}
.shopBagRow:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    /*margin-bottom: 200px;*/
    border: 2px solid #225673;
}

.trolleyPictureBag {
    width: 40px;
    height: 40px;
    align-self: center;
    margin: 5px;
    padding-bottom: 5px;
}

@media screen and (max-width: 500px){
    .trolleyPictureBag {
        width: 30px;
        height: 30px;
    }
}

.promoStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .promoStyle{
        flex-direction: column;
        align-items: center;
    }
}

.promoStyle input{
    width: 150px;
    height: auto;
    font-size: 20px;
    margin: 1%;
}

.promoStyle label{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    font-size: 30px;
    margin: 0;
}

.promoRow {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 380px) {
    .promoRow {
        flex-direction: column;
    }
}

@media screen and (max-width: 850px) {
    .promoStyle label{
        width: 150px;
        font-size: 20px;
    }
}


.promoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-basis: 50%;*/
    min-width: 100px;
    background-color: #FF5757;
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-right: 1.5%;
    margin-left: 1.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    /*min-font-size: 2vw;*/
    font-size: 2vh;
}



@media screen and (max-width: 850px) {
    .promoButton {
        font-size: 20px;
    }
}

.totalCostRow {
    display: flex;
    flex-direction: row;
}

.lineThrough {
    text-decoration: line-through;
}



