.shopInvoiceStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
    background-color: #222222;
}

.shopInvoiceWrapper {
    height: 87%;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 3%;
    padding-top: 3%;
    display: flex;
    flex-direction: column;
}

/*@media screen and (max-width: 1150px) {*/
/*    .shopInvoiceWrapper {*/
/*        !*min-height: 130vh;*!*/
/*        margin-left: 5%;*/
/*        margin-right: 5%;*/
/*        padding-bottom: 3%;*/
/*        padding-top: 3%;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: space-around;*/

/*    }*/
/*}*/

@media screen and (max-width: 700px) {
    .shopInvoiceWrapper {
        /*height: 100%;*/
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom: 3%;
        padding-top: 3%;
        display: block;
        flex-direction: column;
        align-items: center;
        /*justify-content: space-around;*/

    }
}

.invoice {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 3%;
    padding-bottom: 3%;
    align-items: center;
    flex-basis: 80%;
    background: rgb(0,0,0);
    opacity: 0.85;
    border-radius: 80px;
    /*height: 100%;*/
}
@media screen and (max-width: 700px) {
    .invoice {
        margin-left: 10px;
    }
}

.formWrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 87%;
}

@media screen and (max-width: 700px){
    .formWrapper {
        flex-direction: column;
    }
}

.formWrapper label {
    color: white;
    font-size: 20px;
    margin: 5px;
    margin-left: 5%;
    padding-bottom: 10px;
}

.formWrapper p {
    color: white;
    font-size: 30px;
    margin: 5px;
    padding-bottom: 10px;
}

.formRow {
    height: 55px;
}

.formRow input {
    margin: auto;
}

.formWrapper input {
    font-size: 19px;
    height: 29px;
    display: flex;
    width: 90%;
    border-radius: 50px;
    padding-left: 10px;
}


.formPart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

@media screen and (max-width: 700px){
    .formPart {
        width: 100%;
    }
}

.formRequiredPart {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}
@media screen and (max-width: 700px){
    .formRequiredPart {
        width: 100%;
    }
}

.payOrShopButtonsInvoice {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 0px;
    /*align-items: center;*/

}
@media screen and (max-width: 500px) {
    .payOrShopButtonsInvoice {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        margin-bottom: 15px;

    }
}

.shopMoreInvoice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: rgb(255, 222, 89);
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    /*min-font-size: 2vw;*/
    font-size: 2vw;
}

@media screen and (max-width: 850px) {
    .shopMoreInvoice {
        font-size: 20px;
    }
}

.payButtonInvoice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: rgba(203,57,244,1);
    user-select: none;
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    /*min-font-size: 2vw;*/
    font-size: 2vw;
}

@media screen and (max-width: 850px) {
    .payButtonInvoice {
        font-size: 20px;
    }
}

.checkboxStyle {
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-self: center;
    width: 100%;
}

.checkboxStyle label {
    font-size: 15px;
    padding-bottom: 0;
    padding-right: 5%;
    align-self: center;
}

.checkboxStyle input {
    /*margin: 0;*/
    width: auto;
    align-self: center;
}

.personalLine {
    height: calc(0.05*60vw);
    width: 60%;
    align-self: center;
}

@media  screen and (max-width: 800px) {
    .personalLine {
        height: calc(0.05*100vw);
        width: 100%;
    }
}
