#shopMenuToggle
{
    display: block;
    position: absolute;
    top: 5vh;
    right: 30px;

    z-index: 99;

    -webkit-user-select: none;
    user-select: none;
}

#shopMenuToggle a
{
    text-decoration: none;
    /*color: #232323;*/

    transition: color 0.3s ease;
}


#shopMenuToggle a:hover
{
    color: #cdcdcd;
}


#shopMenuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    right: -5px;
    justify-content: center;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#shopMenuToggle img
{
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    right: -4px;
    justify-content: center;

    /*background: #cdcdcd;*/
    /*border-radius: 3px;*/

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
}

#shopMenuToggle span:first-child
{
    transform-origin: 0% 0%;
}

#shopMenuToggle span:nth-last-child(2)
{
    transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#shopMenuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #cdcdcd
}

/*
 * But let's hide the middle one.
 */
#shopMenuToggle input:checked ~ span:nth-last-child(3)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#shopMenuToggle input:checked ~ span:nth-last-child(2)
{
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menuShop
{
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #141414;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(50%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuShop li
{
    padding: 2px 0;
    font-size: 22px;
    width: 70%;
}


/*
 * And let's slide it in from the left
 */
#shopMenuToggle input:checked ~ ul
{
    transform: translate(-60%, 0);
}


.shopRow {
    align-items: center;
    padding: 5px;
    color: white;
    /*margin-bottom: 200px;*/
    border: 2px solid #222222;

}

.shopRow:hover {
    padding: 5px;
    /*margin-bottom: 200px;*/
    border: 2px solid #225673;
}

.itemListScroll {
    overflow: auto;
    height: 12vh;
}

.chosenItemsListElementShopHead {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 0;
    text-align: center;
}

.totalCostMargin {
    color: white;
}

.totalCostText {
    color: white;
    position: absolute;
    width: 170px;
    top: 11px;
    right: -70px;
    text-align: center;
}

.customNavbarEdit {
    color: rgb(255,87,87);
}

.customNavbarOrder {
    color: rgb(255, 222, 89);
}

.navbarTrolley {
    width: 30px;
    height: auto;
}

