@import "~react-image-gallery/styles/css/image-gallery.css";
.shopDetailsStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    /*flex-direction: column;*/
    z-index: 2;
}

@media screen and (max-width: 1500px) {
    .shopDetailsStyle {
        width: 100%;
        /*height: 100vh;*/
        height: 100%;
        top: 0;
        left: 0;
        overflow-scrolling: touch;
        display: flex;
        /*overflow-y: auto;*/
        /*overflow:scroll;*/
    }
}

.shopDetailsBackground {
    /*background-image: url("/src/images/backgroundShopList.png");*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100%;
}

.shopDetailsWrapper {
    height: 85%;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    /*padding-bottom: 3%;*/
    /*padding-top: 3%;*/
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

@media screen and (max-width: 1150px) {
    .shopDetailsWrapper {
        /*min-height: 130vh;*/
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom: 3%;
        padding-top: 3%;
        display: block;
        flex-direction: column;
        justify-content: space-around;

    }
}

@media screen and (max-width: 800px) {
    .shopDetailsWrapper {
        height: auto;
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom: 3%;
        padding-top: 3%;
        display: block;
        flex-direction: column;
        /*justify-content: space-around;*/

    }
}

.chosenItemsList {
    display: flex;
    color:white;
    flex-basis: 15%;
    background: rgba(0,0,0, 0.85);
    border-radius: 160px;
    padding-bottom: 5%;
    margin-left: 5px;
    flex-direction: column;
    /*align-self: auto;*/
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1150px) {
    .chosenItemsList {
        margin-top: 10px;
        display: flex;
        color:white;
        flex-basis: 50%;
        border-radius: 40px;
        padding-bottom: 5%;
        flex-direction: column;
        /*align-self: auto;*/
        align-items: center;
        justify-content: space-between;
    }
}

.chosenItemsListElement {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 0;
    text-align: center;
}
.chosenItemsListElement:hover {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
    margin-left: 0;
    user-select: none;
}

.elementDetails {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 3%;
    padding-bottom: 3%;
    align-items: center;
    flex-basis: 80%;
    background: rgba(0,0,0, 0.85);
    border-radius: 80px;
}

@media screen and (max-width: 1150px) {
    .elementDetails {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        /*flex-basis: 100%;*/
        border-radius: 40px;
        padding-left: 5%;
        padding-right: 5%;
        margin-left: 10px;
    }
}


.imageComponent {
    /*display: flex;*/
    flex-basis: 30%;

}

@media screen and (max-width: 1150px) {
    .imageComponent {
        /*display: flex;*/
        width: 280px;
        margin-top: 20px;

    }

}

@media screen and (max-width: 500px) {
    .imageComponent {
        /*display: flex;*/
        width: 250px;

    }

}

.image-gallery-slide img {
    min-width: 20vw;
}

@media screen and (max-width: 1150px) {
    .image-gallery-slide img {
        min-width: 0vw;
    }

}

.elementDescription {
    color: white;
    display: flex;
    flex-direction: column;
    flex-basis:65%;
    /*padding-left: 5%;*/
    /*padding-right: 5%;*/
}

@media screen and (max-width: 1150px) {
    .elementDescription {
        color: white;
        display: flex;
        flex-direction: column;
        flex-basis:100%;
        /*padding-left: 5%;*/
        /*padding-right: 5%;*/
    }
}

.textDescription {
    display: flex;
    flex-direction: column;
    flex-basis:80%;
    padding-left: 5%;
    padding-right: 5%;
}

.elementCounterAdder {
    display: flex;
    flex-direction: row;
    flex-basis:20%;
    justify-content: center;
    align-items: center;
    /*flex-shrink: 0;*/
}

@media screen and (max-width: 1150px) {
    .elementCounterAdder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
    }
}

.elementCounter {
    display: flex;
    flex-direction: row;
    flex-basis: 40%;
    margin: 3%;
}

@media screen and (max-width: 1150px) {
    .elementCounter {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin: 3%;
    }
}

.elementAdder {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-basis: 10%;*/
    min-width: 100px;
    width: 30%;
    background-color: rgba(203,57,244,1);
    user-select: none;
    text-align: center;
    border-radius: 25px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 3%;
    margin-left: 3%;
    font-size: 15px;
}

@media screen and (max-width: 1150px) {
    .elementAdder {
        display: flex;
        justify-content: center;
        align-items: center;
        /*flex-basis: 10%;*/
        min-width: 50px;
        width: 50%;
        /*max-width: 200px;*/
        background-color: rgba(203,57,244,1);
        user-select: none;
        text-align: center;
        border-radius: 25px;
    }
}

@media screen and (max-width: 600px) {
    .elementAdder {
        display: flex;
        justify-content: center;
        align-items: center;
        /*flex-basis: 10%;*/
        min-width: 50px;
        width: 95%;
        /*max-width: 200px;*/
        background-color: rgba(203,57,244,1);
        user-select: none;
        text-align: center;
        border-radius: 25px;
    }
}

.countButton {
    display: flex;
    flex-basis: 10%;
    min-width: 100px;
    height: 45px;
    /*height: 100%;*/
    color: black;
    background-color: rgb(255, 222, 89);
    user-select: none;
    text-align: center;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
}

.countButton p {
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    /*height: 100%;*/
    /*text-align: center;*/
}



.countNumber {
    display: flex;
    /*border-radius: 50%;*/
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    /*border-color: white;*/
    /*border-style: solid;*/
    /*border-bottom: 2px white solid;*/
}

@media screen and (max-width: 1150px){
    .countNumber {
        display: flex;
        border-radius: 50%;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        justify-content: center;
        width: 50px;
        height: 50px;
        text-align: center;
    }
}

.elementCounter p {
    margin-bottom: 0;
    margin: auto;
}

.itemName {
    /*display: flex;*/
    font-size: 50px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    width: auto;
    text-align: left;
    white-space: break-spaces;
}

@media screen and (max-width: 1150px){
    .itemName {
        font-size: 40px;
    }
}
.itemType {
    font-size: 30px;
    margin-bottom: 5px;
}
.itemDescription {
    font-size: 20px;
    white-space: break-spaces;
    overflow-y: scroll;
    height: 30vh;

}

.totalCostMargin {
    text-align: center;
    border-top: 2px solid white;
    font-size: 20px;
}

.TotalCost {

}

.payNow {
    margin-top: 20px;
    align-self: center;
}


.payWrapper {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
}

.payNowButtonDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
    min-width: 100px;
    background-color: rgb(255, 222, 89);
    user-select: none;
    /*text-decoration: none;*/
    text-align: center;
    border-radius: 45px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 3%;
    /*min-font-size: 2vw;*/
    /*font-size: 2vw;*/
    font-size: 20px;
}

.bagRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /*margin-bottom: 200px;*/
    border: 3px solid #222222;
}
.bagRow:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /*margin-bottom: 200px;*/
    border: 3px solid #225673;
}

.trashImage {
    margin: 5px;
    width: 24px;
    height: 24px;
}

.payWrapperItemsList {
    overflow: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.loading {
    width:100vw;
    height:87vh;
    background-color: #222222;
}

.shopBagImageStyle {
    width: 40px;
    height: 40px;
}

.squareRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media screen and (max-width: 450px){
    .squareRow {
        flex-direction: column;
    }
}

.square {
    min-width: 150px;
    text-align: center;
    font-size: 22px;
    border: 3px solid;
    margin-left: 5px;
    margin-right: 5px;
}

.borderColorPrice {
    border-color: #FF66C4;
}

.borderColorCat {
    border-color: #FFDE59;
}

.borderColorType {
    border-color: #FF66C4;
}

/*ADDITIONAL ITEMS*/

.checkAlso.shopItemLinkWrapper {
    flex-basis: 45%;
    /*flex-grow: 1;*/
    /*flex-shrink: 1;*/
    padding: 20px;
}

@media screen and (max-width: 900px){
    .checkAlso.shopItemLinkWrapper {
        flex-basis: 100%;
        /*flex-grow: 1;*/
        /*flex-shrink: 1;*/
        padding: 5px;
    }
}

.checkAlso.shopItem {
    align-items: center;
    border-radius: 170px;
    background: rgba(0,0,0, 1);
    /*opacity: 0.85;*/
    width: 100%;
    display: flex;
    flex-basis: 40%;
    /*max-width: 80%;*/
    flex-direction: row;
    position: relative;

}

@media screen and (max-width: 550px){
    .checkAlso.shopItem {
        align-items: center;
        border-radius: 170px;
        /*background: rgb(0,0,0);*/
        /*opacity: 0.85;*/
        width: 100%;
        display: flex;
        flex-basis: 40%;
        /*max-width: 80%;*/
        flex-direction: row;
        position: relative;

    }
}

.checkAlso.shopItemImageContainer {
    display: flex;
    max-width: 40%;
    /*max-height: 270px;*/
    z-index: 1;
}

@media screen and (max-width: 550px){
    .checkAlso.shopItemImageContainer {
        max-width: 60%;
        height: 200px;
    }
}

.checkAlso.shopItemImage {
    display: flex;
    max-width: 100%;
    /*max-height: 270px;*/
    z-index: 1;
}

@media screen and (max-width: 550px){
    .checkAlso.shopItemImage {
        width: 100%;
        height: 200px;
    }
}

.checkAlso.nameElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 45%;
    min-height: 200px;
    padding-left: 2%;
}

.checkAlso.nameSize {
    font-size: 2vw;
    line-height: 1.1;
    text-align: center;
    font-family: Georgia;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: break-spaces;
}
@media screen and (max-width: 900px){
    .checkAlso.nameSize {
        font-size: 4vw;
        line-height: 1;
    }
}

.checkAlso.subjectSize {
    font-size: 1.5vw;
    font-family: Georgia;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 900px){
    .checkAlso.subjectSize {
        font-size: 3vw;
    }
}

.checkAlso.price {
    height: 20%;
    width: 35%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    left: 15%;
    border-radius: 50px;
    padding-left: 5%;
    color: white;
    z-index: 98;
    font-size: 200%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Georgia;
}

@media screen and (max-width: 800px){
    .checkAlso.price {
        /*bottom: 1;*/
    }
}

.checkAlso.priceText {
    margin: auto;
    margin-left: 0;
}

.checkAlso.addToCartButton {
    height: 20%;
    width: 30%;
    background: rgba(1,123,23,0.5);
    position: absolute;
    bottom: 0;
    right: 25%;
    border-radius: 50px;
    /*padding-left: 5%;*/
    color: white;
    /*background-color: red;*/
    z-index: 100;
    font-size: 200%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Georgia;
}

.checkAlso.addToCartButtonText {
    margin: auto;
    /*margin-left: 0;*/
}

.checkAlso.addToCartButtonText {
    margin: auto;
    /*margin-left: 0;*/
}

.checkAlso {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%;
}
