.messageStyle {
    width: 100%;
    height: 100%;
    overflow-scrolling: touch;
    overflow: auto;
    /*position: absolute;*/
    display: flex;
    z-index: 2;
}

.messageBackground {
    background-image: url("/src/images/backgroundSendMessage.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*height: 100%;*/
}
.messageWrapper {
    width: 100%;
    height: auto;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-bottom: 13vh;*/
}

.writeToUs {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 120px;
    color: rgba(8,195,201,1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputName {
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: rgba(255,255,255,1);
}

.inputSmaller {
    border: 8px solid;
    border-radius: 20px;
    background: rgba(255, 222, 89, 0.83);
    border-color: rgba(255,189,89,0.83);
    width: 60%;
    min-height: 5vh;
    font-size:25px;
    padding-left: 20px;
    display: flex;
    flex-basis: 60%;
}

.inputBigger {
    resize: none;
    border: 8px solid;
    border-radius: 50px;
    background: rgba(255, 222, 89, 0.83);
    border-color: rgba(255,189,89,0.83);
    /*width: 90%;*/
    height: 30%;
    min-height: 30vh;
    padding: 12px 20px;
    font-size: 15px;
    /*overflow: hidden;*/
    /*overflow-y: scroll;*/
}
/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

@media screen and (max-width: 1150px){
    .inputBigger {
        padding: 12px 20px;
        font-size: 15px;
        /*display: flex;*/
    }
}

@media screen and (max-width: 1150px) {
    .inputSmaller {
        border-radius: 20px;
        /*width: 100%;*/
        /*min-height: 5vh;*/
        font-size: 15px;
        padding-left: 20px;
        display: flex;
        /*flex-basis: 90%;*/
        /*width: 100%;*/
    }
}
.inputSmaller:focus {
    outline: 0;
}
.inputBigger:focus {
    outline: 0;
}

.submitStyle {
    border-radius: 20px;
    background: rgba(255, 222, 89, 0.83);
    margin: 5px;
    width: 135px;
    min-height: 5vh;
    font-size:25px;
    display: flex;
    /*flex-basis: 60%;*/
    text-align: center;
    justify-content: center;
    font-family: Georgia;
}

.formStyle {
    /*margin-bottom: 20px;*/
}

.inputWrapper {
    display: flex;
    flex-direction: column;
}

.thankImage {
    margin: auto;
    width: 70%;
    /*margin-top: 13vh;*/
    height: auto;
    padding-bottom: 13vh;
}

@media screen and (max-width: 1150px) {
    .thankImage {
        width: 100%;
    }
}


