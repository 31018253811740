.appStyle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #222222;
  /*overflow: hidden;*/
}

.contentWrapperStyle {
  position: fixed;
  /*top: 13vh;*/
  /*margin-right: 5%;*/
  /*margin-left: 5%;*/
  top: 13vh;
  bottom: 25px;
  /*margin-bottom: 2vh;*/
  width: 100vw;
  height: auto;
  overflow: auto;
  /*overflow-y: visible;*/
}

p {
  text-decoration: none;
}

a {
  text-decoration: none;
}

p {
  font-family: Georgia;
}

div {
  font-family: Georgia;
  /*overflow-x: hidden;*/
}
button {
  font-family: Georgia;
  border: 0 #222222;
  transition-duration: 0.4s;
}
button:hover {
  filter: brightness(85%)
}
button:active {
  filter: brightness(30%)
}

body {
  overflow-x: hidden;
}

nav {
  overflow: hidden;
}

input {
  border: 0;
}

.footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  width: 100vw;
  height: 25px;
  background-color: #222222;
  color: white;
  justify-content: center;
  align-items: center;
}

.iconFooter {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.footerMargin {
  margin-left: 2px;
  margin-right: 2px;
  color: white;
}

.linkFooter {

}


